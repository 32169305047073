import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RouteService {
  current: any;

  constructor(private router: Router, private route: ActivatedRoute) {}

  /**
   * @description
   * Emite o campo `data` de uma rota sempre quando a rota da aplicação muda.
   *
   * Realizamos um loop enquanto existem rotas filhas, pois é possível
   * que as rotas sejam aninhadas.
   *
   * @param callback Função que será invocada com o parâmetro `data` da rota.
   */
  getRouteDataOnRouteChange(callback: (data: any) => void) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route?.snapshot.data;
        }),
      )
      .subscribe((res) => {
        this.current = res;
        callback(res);
      });
  }
}

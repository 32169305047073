export interface AnalyticsEventOption {
  component?: string;
  action?: string;
  step?: string;
  description?: string;
  extras?: object;
  currencyCode?: string;
  value?: number;
  fatal?: boolean;
}

export enum PriceMethodType {
  Pix = "pix",
  CreditCard = "credit_card",
  BankSlip = "bank_slip",
  VivoPrm = "vivo_prm",
}

export enum ItemBrandTypes {
  Atma = "atma",
  ValeSaude = "vale-saude",
  Vivae = "vivae",
}

export enum PaymentType {
  pix = "pix",
  credit_card = "credit-card",
  bank_slip = "boleto",
  vivo_prm = "fatura-vivo",
}

export enum ECommerceEventType {
  BeginCheckout = "begin_checkout",
  AddPaymentInfo = "add_payment_info",
  Purchase = "purchase",
}

export interface ECommerceItem {
  item_name: string;
  item_id: string;
  price: number;
  item_brand: ItemBrandTypes;
  item_category: string;
  item_list_id: string;
  item_list_name: string;
  quantity: number;
  index: number;
}

export interface ECommerceEvent {
  item_list_id?: string;
  item_list_name?: string;
  currency?: string;
  value?: number;
  payment_type?: PaymentType;
  transaction_id?: string;
  option?: PaymentType;
  items: ECommerceItem[];
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StepService {
  private step = new BehaviorSubject<number>(2);

  setStep(step: number) {
    this.step.next(step);
  }

  getStep() {
    return this.step.asObservable();
  }
}

<ul class="steps" *ngIf="steps.length">
  <!--Descrição para screen readers-->
  <p class="s-is-hidden">Etapas do pagamento</p>

  <ng-container *ngIf="step$ | async as current">
    <span class="steps__bar" aria-hidden="true">
      <span
        class="steps__bar-inner"
        [ngStyle]="{ width: ((current - 1) / (steps.length - 1)) * 100 + '%' }"
      >
      </span>
    </span>

    <li
      class="steps__item"
      [ngClass]="{ '--is-completed': index + 1 <= current, '--current': index + 1 === current }"
      *ngFor="let step of steps; let index = index"
    >
      {{ step }}

      <!--Descrição para screen readers-->
      <p class="s-is-hidden">
        {{ index + 1 | statusByStep : current }}
      </p>
    </li>
  </ng-container>
</ul>

import { Component, OnInit } from "@angular/core";

import { AnalyticsService } from "@shared/services/analytics/analytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private analytics: AnalyticsService) {}

  ngOnInit() {
    this.analytics.initScreenViewRouting();
  }
}

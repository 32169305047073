import { Injectable } from "@angular/core";

import { ErrorLoggerService } from "../error-logger/error-logger.service";

export interface KondutoParams {
  getVisitorID: () => string;
  sendEvent: (name: string, value: any) => {};
}

declare const Konduto: KondutoParams;

@Injectable({
  providedIn: "root",
})
export class AntiFraudService {
  constructor(private errorLoggerService: ErrorLoggerService) {}

  getVisitorId(): string {
    return Konduto?.getVisitorID() || "";
  }

  sendEvent(name: string, value: string) {
    try {
      Konduto.sendEvent(name, value);
    } catch (e) {
      this.errorLoggerService.sendException("Error sending anti-fraud event", {
        level: "error",
        context: "AntiFraudService.sendEvent",
        extra: {
          name,
          value,
          error: e,
        },
      });
    }
  }
}

import { CheckoutCreateProps } from "@app/data/services/checkout/checkout.service";
import { RenewalInfo } from "@app/data/types/renewalInfo";

export enum PaymentMethodTypes {
  BankSlip = "bank-slip",
  CreditCard = "credit_card",
  Subscription = "subscription",
  Invoice = "invoice",
  Pix = "pix",
}

export interface PaymentMethodRenewal {
  createAndRedirect(data: RenewalInfo): void;
  createProps(data: RenewalInfo): CheckoutCreateProps;
}

interface PaymentMethod {
  icon: string;
  label: string;
  type: string;
  period: string;
  price?: string;
}

export const cc: PaymentMethod = {
  icon: "icon-card",
  label: "Cartão de crédito",
  period: "por mês",
  type: PaymentMethodTypes.Subscription,
};

export const ccWithInstallments: PaymentMethod = {
  icon: "icon-card",
  label: "Cartão de crédito",
  period: "",
  type: PaymentMethodTypes.CreditCard,
};

export const slip: PaymentMethod = {
  icon: "icon-bar-code",
  label: "Boleto",
  period: "à vista",
  type: PaymentMethodTypes.BankSlip,
};

export const invoice: PaymentMethod = {
  icon: "icon-vivo",
  label: "Sua conta da Vivo",
  period: "",
  type: PaymentMethodTypes.Invoice,
};

export const pix: PaymentMethod = {
  icon: "icon-pix",
  label: "Pix",
  period: "à vista",
  type: PaymentMethodTypes.Pix,
};

export const paymentMethods: PaymentMethod[] = [];

<app-navbar></app-navbar>

<main class="app">
  <div class="app__content">
    <app-steps></app-steps>

    <app-spacer [mobile]="20" [desktop]="40"></app-spacer>

    <router-outlet></router-outlet>
  </div>
</main>

import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { EnvironmentProviders, ErrorHandler, LOCALE_ID, NgModule, Provider } from "@angular/core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";

import { ErrorInterceptor } from "@core/interceptors/error/error-interceptor/error.interceptor";
import { NavbarModule } from "@layouts/navbar/navbar.module";
import { SpacerModule } from "@shared/components/spacer/spacer.module";
import { StepsModule } from "@shared/components/steps/steps.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ToastModule } from "./shared/components/toast/toast.module";

import { environment } from "../environments/environment";

registerLocaleData(ptBr);

const providers: Array<Provider | EnvironmentProviders> = [
  { provide: LOCALE_ID, useValue: "pt" },
  { provide: "Window", useValue: window },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
];

export function errorHandlerFactory(): BugsnagErrorHandler {
  return new BugsnagErrorHandler();
}

if (environment.BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: environment.BUGSNAG_KEY,
    appVersion: environment.version,
    redactedKeys: ["cpf", "phone"],
  });

  providers.push({ provide: ErrorHandler, useFactory: errorHandlerFactory });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    AppRoutingModule,
    NavbarModule,
    StepsModule,
    SpacerModule,
    RouterModule.forRoot([], { scrollPositionRestoration: "enabled" }),
    ToastModule,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-title",
  templateUrl: "./title.component.html",
  styleUrls: ["./title.component.scss"],
})
export class TitleComponent {
  @Input() color = "dark-shade";

  @Input() weight = "700";

  @Input() size = "";

  @Input() flex = false;
}

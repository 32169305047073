<div class="navbar">
  <nav class="navbar__wrapper">
    <img
      class="navbar__logo"
      src="assets/images/logo-vs.svg"
      alt="Logotipo da Vale Saúde"
      width="152"
      height="32"
      loading="lazy"
    />

    <div class="navbar__safe-area" tabindex="-1">
      <i class="icon-lock" aria-hidden="true"></i>

      <p>Ambiente seguro</p>
    </div>
  </nav>
</div>

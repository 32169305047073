import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { catchError, finalize, map, Observable } from "rxjs";

import { CheckoutService } from "@data/services/checkout/checkout.service";
import { LoadingDialogService } from "@shared/services/loading-dialog/loading-dialog.service";
import { AnalyticsService } from "@shared/services/analytics/analytics.service";

@Injectable({
  providedIn: "root",
})
export class UserResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private loadingDialogService: LoadingDialogService,
    private analyticsService: AnalyticsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.loadingDialogService.show();
    return this.checkoutService.getById(`check-renewal/${route.paramMap.get("id")}`).pipe(
      map((res) => {
        this.analyticsService.user.next(res.data?.customer);
        return res;
      }),
      catchError(async (err) => {
        await this.router.navigate(["/"]);
        return err;
      }),
      finalize(() => this.loadingDialogService.close()),
    );
  }
}

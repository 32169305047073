import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { StepsComponent } from "./steps.component";
import { StatusByStepPipe } from "./pipes/status-by-step/status-by-step.pipe";

@NgModule({
  declarations: [StepsComponent, StatusByStepPipe],
  imports: [CommonModule],
  exports: [StepsComponent],
  providers: [],
})
export class StepsModule {}

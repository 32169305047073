import { Component } from "@angular/core";
import { Observable } from "rxjs";

import { StepService } from "@shared/components/steps/services/step/step.service";

@Component({
  selector: "app-steps",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent {
  steps = ["Cadastro", "Pagamento", "Conclusão"];

  step$: Observable<number>;

  constructor(private stepService: StepService) {
    this.step$ = stepService.getStep();
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ToastComponent } from "./toast.component";

import { TextModule } from "../text/text.module";

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, TextModule],
  exports: [ToastComponent],
})
export class ToastModule {}

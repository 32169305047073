import { HttpErrorResponse } from "@angular/common/http";

export const handleError = (err: HttpErrorResponse): any => {
  let {
    fields,
    // eslint-disable-next-line prefer-const
    message = "Erro ao realizar requisição. Tente novamente!",
    // eslint-disable-next-line prefer-const
    code = "",
  } = err?.error?.error || err?.error;

  if (!fields) {
    fields = err.error.errors;
  }

  return { fields, message, code };
};

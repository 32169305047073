import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

import { RenewalInfo } from "@data/types/renewalInfo";
import {
  cc,
  ccWithInstallments,
  invoice,
  paymentMethods,
  pix,
  slip,
} from "@modules/payment-method/data";

import { environment } from "../../../../environments/environment";

export interface AntiFraudProps {
  visitor: string;
  bin?: string;
  last4?: string;
  expiration_date?: string;
}

export declare type PaymentType = "bank_slip" | "pix" | "invoice" | "credit_card" | "subscription";
export interface CheckoutCreateProps {
  name: string;
  cpf: string;
  email: string;
  id: string;
  type: PaymentType;
  renewal: number;
  analyze: AntiFraudProps;
  card_name?: string;
  card_cpf?: string;
  token?: string;
  installments?: number;
}

const PATH = "checkout";
const VERSION = "v3";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  constructor(private httpClient: HttpClient) {}

  createPaymentMethods(data: RenewalInfo): any[] {
    const {
      bank_slip: bankslipPrice,
      vivo_prm: invoicePrice,
      subscription: subscriptionPrice,
      credit_card: creditCardPrice,
      credit_card_installments: installments = 1,
      pix: pixPrice,
    } = data || {};

    const methods = [...paymentMethods];

    if (subscriptionPrice) {
      methods.push({
        ...cc,
        price: subscriptionPrice,
      });
    }

    if (creditCardPrice) {
      methods.push({
        ...ccWithInstallments,
        price: creditCardPrice,
        period: `em até ${installments}x <br/>`,
      });
    }

    if (bankslipPrice) {
      methods.push({
        ...slip,
        price: bankslipPrice,
      });
    }

    if (invoicePrice) {
      methods.unshift({
        ...invoice,
        price: invoicePrice,
      });
    }

    if (pixPrice) {
      methods.push({
        ...pix,
        price: pixPrice,
      });
    }

    return methods;
  }

  getById(id: string) {
    return this.httpClient.get(`${environment.urlApi}/${VERSION}/${PATH}/${id}`).pipe(
      map((res: any) => {
        res.data.payment_methods = this.createPaymentMethods(res.data);
        return res;
      }),
    );
  }

  createById(id: string, params: CheckoutCreateProps) {
    return this.httpClient.post(`${environment.urlApi}/${VERSION}/${PATH}/${id}`, params);
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusByStep",
})
export class StatusByStepPipe implements PipeTransform {
  transform(index: number, current: number): string {
    if (current === index) return "Etapa atual";
    if (current > index) return "Concluído";
    return "Pendente";
  }
}

import { Injectable } from "@angular/core";

import { ErrorLoggerWrapperService } from "./error-logger-wrapper/error-logger-wrapper.service";
import { ErrorLoggerParams } from "./error-logger.data";

@Injectable({
  providedIn: "root",
})
export class ErrorLoggerService {
  constructor(private errorLoggerWrapperService: ErrorLoggerWrapperService) {}

  sendException(exception: Error | string, params?: ErrorLoggerParams): void {
    this.errorLoggerWrapperService.captureException(exception, (ev) => {
      if (!params) return;

      const { level, context, extra = {} } = params;

      ev.severity = level || "error";

      ev.context = context;

      ev.addMetadata("extra", extra);
    });
  }
}

import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

import { LoadingDialogData } from "@data/types/loading-dialog";

@Component({
  selector: "app-loading-dialog",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"],
})
export class LoadingDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LoadingDialogData) {}
}

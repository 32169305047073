import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Injectable } from "@angular/core";

import { LoadingDialogComponent } from "@layouts/loading-dialog/loading-dialog.component";

import { dialogConfig } from "./data";

@Injectable({
  providedIn: "root",
})
export class LoadingDialogService {
  constructor(public dialog: MatDialog) {}

  show(message = "") {
    const config = {
      ...dialogConfig,
      data: {
        message,
      },
    };

    this.dialog.open(LoadingDialogComponent, config);
  }

  close() {
    this.dialog.closeAll();
  }
}

<div>
  <app-spinner></app-spinner>

  <ng-container *ngIf="data?.message as message">
    <app-spacer [mobile]="12" [desktop]="12"></app-spacer>

    <app-title weight="500" size="small">
      <p>
        {{ message }}
      </p>
    </app-title>
  </ng-container>
</div>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { UserResolver } from "@data/resolvers/user/user.resolver";

const routes: Routes = [
  {
    path: "renovacao/:id",
    resolve: {
      user: UserResolver,
    },
    children: [
      {
        path: "",
        redirectTo: "metodo-de-pagamento",
        pathMatch: "full",
      },
      {
        path: "metodo-de-pagamento",
        loadChildren: () =>
          import("./modules/payment-method/payment-method.module").then(
            (m) => m.PaymentMethodModule,
          ),
      },
    ],
  },
  {
    path: "pagina-nao-encontrada",
    loadChildren: () =>
      import("./modules/page-not-found/page-not-found.module").then((m) => m.PageNotFoundModule),
  },

  //Non handled routes
  {
    path: "**",
    redirectTo: "/pagina-nao-encontrada",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

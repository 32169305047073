import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import {
  AnalyticsEventOption,
  ECommerceEvent,
  ECommerceEventType,
  ECommerceItem,
  ItemBrandTypes,
  PaymentType,
  PriceMethodType,
} from "@shared/directives/analytics/analytics.models";
import { common } from "@shared/services/analytics/data";
import { RouteService } from "@shared/services/route/route.service";
import { encrypt } from "@utils/encrypt/encrypt";
import { removeEmptyFields } from "@utils/object/object";
import slugify from "@utils/slugify";
import { transformStringToPrice } from "@utils/strings/currency";
import { AntiFraudService } from "@shared/services/anti-fraud/anti-fraud.service";
import { transformDate } from "@utils/date/date";
import { RenewalInfo } from "@app/data/types/renewalInfo";

import { PageView, PageViewEvent, UserProps } from "./analytics.models";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  user: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private routeService: RouteService, private antiFraudService: AntiFraudService) {}

  initScreenViewRouting() {
    this.routeService.getRouteDataOnRouteChange(this.sendPageView);
  }

  sendPageView = ({ pagePath, subCategory = "" }: PageView) => {
    const { value } = this.user;

    if (!pagePath) {
      return;
    }

    const pageViewEvent: PageViewEvent = {
      event: "pageview",
      page: {
        ...common,
        pagePath,
        subCategory,
      },
    };

    if (value) {
      pageViewEvent.page.geoRegion = slugify(value.address?.state || "");
      pageViewEvent.user = this.createUser(value);
    }

    window.dataLayer.push(pageViewEvent);
    this.antiFraudService.sendEvent("page", pagePath);
  };

  transformProducts(source: any, priceMethod: PriceMethodType): Array<ECommerceItem> {
    const price = transformStringToPrice(source[priceMethod]);
    const name = slugify(source.name);

    return [
      {
        item_name: name,
        item_id: source.program_id,
        price,
        item_brand: ItemBrandTypes.ValeSaude,
        item_category: "link-de-pagamento",
        item_list_id: `metodo-pagamento:${PaymentType[priceMethod]}`,
        item_list_name: "link-de-pagamento",
        quantity: 1,
        index: 1,
      },
    ];
  }

  sendECommerceEvents(
    renewalData: RenewalInfo,
    priceMethod: PriceMethodType,
    eCommerceEvent: ECommerceEventType,
  ) {
    const price = transformStringToPrice(renewalData[priceMethod] || "0");
    const items = this.transformProducts(renewalData, priceMethod);

    if (eCommerceEvent === ECommerceEventType.BeginCheckout) {
      this.eCommerceLogEvent("begin_checkout", {
        item_list_id: `metodo-pagamento:${PaymentType[priceMethod]}`,
        item_list_name: "link-de-pagamento",
        items,
      });
      return;
    }
    if (eCommerceEvent === ECommerceEventType.AddPaymentInfo) {
      this.eCommerceLogEvent("add_payment_info", {
        currency: "BRL",
        value: price,
        payment_type: PaymentType[priceMethod],
        items,
      });
      return;
    }
    if (eCommerceEvent === ECommerceEventType.Purchase) {
      const date = transformDate();
      this.eCommerceLogEvent("purchase", {
        currency: "BRL",
        transaction_id: `${renewalData.id}-${date}`,
        value: price,
        payment_type: PaymentType[priceMethod],
        items,
      });
      return;
    }
  }

  sendExceptionFormEvent(field: string, fatal: boolean, message?: string) {
    const params: AnalyticsEventOption = {
      description: `${field}${message ? ":" + slugify(message) : ""}`,
      fatal,
    };

    this.logEvent("exception", params);
  }

  eCommerceLogEvent(event: string, options: ECommerceEvent) {
    const fields = removeEmptyFields({ ...options });

    const logEventBody = {
      event,
      ecommerce: { ...fields },
    };

    window.dataLayer.push(logEventBody);
  }

  logEvent(event: string, options: AnalyticsEventOption) {
    if (!event) {
      return;
    }

    const logEventBody: any = { event };
    const fields = removeEmptyFields({ ...options, ...options.extras });

    logEventBody[event] = fields;
    delete logEventBody[event].extras;

    window.dataLayer.push(logEventBody);
  }

  createUser(params: any): UserProps {
    return {
      id: encrypt(params.cpf),
      cpf: encrypt(params.cpf),
      msisdn: encrypt(params.celphone || params.phone),
      email: encrypt(params.email),
      address: {
        city: slugify(params.address?.city),
        state: slugify(params.address?.state),
        postalCode: encrypt(params.address?.zipcode),
        country: "brasil",
      },
    };
  }
}
